@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%;
  min-width: 1000px;
}

.iconfont {
  fill: currentColor;
  overflow: hidden;
}
